interface ISubject {
  subject_title: string
}

export const useSubjectStore = defineStore('subject', {
  state: () => {
    return {
      subject: {
        subject_title: '',
      } as ISubject
    }
  },
  getters: {
    subject_title: (state): ISubject => state.subject.subject_title,
  },
  actions: {
    setSubject(data: ISubject) {
      this.subject = data;
    },
    resetSubject() {
      this.subject = {
        subject_title: '',
      };
    },
  },
})

if (import.meta.hot) {  //HMR
  import.meta.hot.accept(acceptHMRUpdate(useSubjectStore, import.meta.hot))
}
